import convertingRatio from "../en/convertingRatio";
import sl from "../ar/socialLinks";

export default {
  newCashier: "إضافة كاشير جديد",
  phoneNumber: "رقم الهاتف",
  name: "الاسم",
  branch: "فرع",
  noMatch: "لا توجد نتائج",
  somethingWentWrong: convertingRatio.somethingWentWrong,
  noCashiersAreAddedYet: "لم يتم إضافة موظفي كاشير حتي الان",
  searchForCashiers: "البحث عن موظفي الكاشير",
  addCallCenterTitle: " إضافة لخدمة العملاء",
  addToBranchTitle: " إضافة الي فرع",
  all: "الكل",
  unassigned: "غير معين",
  assigned: "تعيين",
  UNASSIGNED: "UNASSIGNED",
  cashierName: " اسم الكاشير",
  assignToBranches: "تعيين لفرع",
  password: "الرقم السري",
  integrationId: "رقم التكامل",
  addBranchTitle: "إضافة فرع جديد",
  unassignedCashiers: "كاشير غير معين",
  assignedCashiers: "كاشير معين",
  addCashier: "إضافة كاشير",
  editCashier: "تعديل كاشير",
  viewCashier: "عرض الكاشير",
  plus: "+",
  thereIsNoMatch: "لا يوجد تطابق!",
  submit: sl.submit,
  deleteCashier: "حذف الكاشير",
  deleteCashierDescription:
    "أنت على وشك حذف هذا الكاشير، قم بالتأكيد للاستمرار",
  confirmDelete: "تأكيد",
  cancel: "إلغاء",
  callCenterBranch: "كول سنتر",
};
